<template>
  <div class="container py-4">
    <div class="bg-white">
      <div class="flex items-center">
        <div class="flex items-center">
          <div class="ml-10 font-bold">{{ $t('uptodate.lbl_search_result') }}:</div>
          <div class="ml-2">{{ searchValue }}</div>
        </div>
        <div class="ml-auto flex items-center py-2 pr-3">
          <div class="copyright">
            <div class="ml-24">UpToDate &reg;</div>
            <div>https://www.uptodate.com/</div>
          </div>
          <div @click="closeSearchResult" class="close-button cursor-pointer ml-2">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="23.2937"
                y="8.11432"
                width="2"
                height="22"
                rx="1"
                transform="rotate(45 23.2937 8.11432)"
                fill="#4F4F4F"
              />
              <rect
                x="24.7079"
                y="23.6707"
                width="2"
                height="22"
                rx="1"
                transform="rotate(135 24.7079 23.6707)"
                fill="#4F4F4F"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="border-bottom ml-6 mr-6"></div>
      <div class="min-h-250 flex flex-col w-full" v-loading="isLoading">
        <div v-if="listArticles.length > 0">
          <div
            v-for="item in listArticles"
            :key="item.id"
            class="mt-6 mb-12 ml-12 mr-12 cursor-pointer group"
          >
            <a
              :target="item.target"
              :href="item.href"
              class="h2 text-lg txt-pri-900 group-hover:text-blue-500 transition-all duration-300"
            >{{ item.title }}</a>
            <div v-html="item.snippet"></div>
          </div>
        </div>

        <div
          class="no-result py-2 my-auto"
          v-else-if="listArticles.length === 0 && !isLoading"
        >{{ searchValue ? $t('multidisciplinary_board.lbl_notfound') : $t('uptodate.lbl_no_keyword') }}</div>
      </div>
    </div>
    <AppPagination :paginationProps="pagination" @onChangePagination="handleChangePagination" />
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'

export default {
  name: 'SearchBarHeader',
  components: { AppPagination },
  data () {
    return {
      isLoading: false,
      listArticles: [],
      searchValue: '',
      dialogVisible: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },

  mounted () {
    this.searchValue = this.$route.query?.slug
    this.handleListChange(this.searchValue)
  },
  watch: {
    '$route.query.slug': {
      handler (data) {
        this.searchValue = data
      }
    },
    searchValue: {
      handler (value, oldValue) {
        if (value === oldValue) return
        this.handleListChange(value)
      }
    }
  },
  methods: {
    async handleListChange (value) {
      if (!value || value === '') return

      const self = this
      self.isLoading = true
      try {
        const params = {
          keyword: value,
          max: self.pagination.pageSize,
          searchOffset: self.pagination.currentPage,
          language: 'en'
        }
        const result = await self.$rf
          .getRequest('DoctorRequest')
          .getListArticle(params)

        const responseData = result.data.data
        this.listArticles = responseData?.searchResults?.map(item => {
          if (!item.url) return item

          if (
            process.env.VUE_APP_UPTODATE_DOMAIN === 'https://www.uptodate.com'
          ) {
            const absoluteURL = `${process.env.VUE_APP_UPTODATE_DOMAIN}${item.url}`
            return { ...item, href: absoluteURL, target: '_blank' }
          }

          const slug = item.url.split('/')[2].split('?')[0]
          const location = `${window.location.origin}/#/doctor/uptodate?topicId=${item.id}&slug=${slug}`
          const absoluteURL = new URL(location).href

          return { ...item, href: absoluteURL, target: '_self' }
        })
        this.pagination.totalItems = responseData?.page?.total
        this.isLoading = false
      } catch (error) {
        console.log('No result', error)
        this.listArticles = []
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    async openDetailPage (item) {
      // this.$router.push({ path: '/doctor/uptodate', query: { slug: slug } })
      const slug = item.url.split('/')[2].split('?')[0]
      const location = `${window.location.origin}/#/doctor/uptodate?slug=${slug}`
      const absoluteURL = new URL(location).href
      window.open(absoluteURL, '_blank')
    },
    async closeSearchResult () {
      this.$store.commit('upToDate/setSearchValue', '')
      this.$router
        .push({
          path: '/'
        })
        .catch(() => {})
    },
    handleChangePagination (data) {
      if (data.currentPage) {
        this.pagination.currentPage = data.currentPage
      }

      if (data.pageSize) {
        this.pagination.pageSize = data.pageSize
      }

      this.handleListChange(this.searchValue)
    }
  }
}
</script>
<style scoped>
/* style for search bar is here */
.result {
  background-color: #fff;
  width: 95%;
  height: auto;
}

.close {
  cursor: pointer;
  margin-left: auto;
}

.search-result {
  /* margin-left: auto; */
  margin-left: 2rem;
}

.search-value {
  /* margin-left: auto; */
  margin-left: 4rem;
}

.copyright {
  color: #16cc4f;
  margin-left: 5rem;
  margin-top: 8px;
}

.no-result {
  text-align: center;
  font-size: 16px;
}

/* style for search bar is here */
</style>

import { APP_MODE, FEATURE_SERVICES } from '../../utils/constants'
import {
  AdmissionIcon,
  CarePlanIcon,
  CarePlanOverviewIcon,
  ChatIcon,
  CircleIcon,
  ConferenceIcon,
  ConsultationIcon,
  CreditIcon,
  EcomMarketplaceIcon,
  EcomMarketplaceWarehouseIcon,
  ElectronicMedicalRecordIcon,
  ExaminationScheduleIcon,
  HealthRecordIcon,
  HomeIcon,
  IndividualIcon,
  MeetingIcon,
  OrgLabManageIcon,
  OrgManageIcon,
  OrgPersonnelIcon,
  OrgWorkspaceIcon,
  PatientIcon,
  RoomManagementIcon,
  ScheduleIcon,
  WorkspaceSettingIcon,
  SignatureIcon
} from '../Icon'

import Vue from 'vue'
import appUtils from '../../utils/appUtils'

Vue.component('home-icon', HomeIcon)
Vue.component('org-manage-icon', OrgManageIcon)
Vue.component('conference-icon', ConferenceIcon)
Vue.component('health-record-icon', HealthRecordIcon)
Vue.component('electronic-medical-record-icon', ElectronicMedicalRecordIcon)
Vue.component('care-plan-overview-icon', CarePlanOverviewIcon)
Vue.component('care-plan-icon', CarePlanIcon)
Vue.component('patient-icon', PatientIcon)
Vue.component('admission-icon', AdmissionIcon)
Vue.component('examination-schedule-icon', ExaminationScheduleIcon)
Vue.component('room-management-icon', RoomManagementIcon)
Vue.component('schedule-icon', ScheduleIcon)
Vue.component('consultation-icon', ConsultationIcon)
Vue.component('meeting-icon', MeetingIcon)
Vue.component('chat-icon', ChatIcon)
Vue.component('circle-icon', CircleIcon)
Vue.component('ecom-marketplace-icon', EcomMarketplaceIcon)
Vue.component('ecom-marketplace-warehouse-icon', EcomMarketplaceWarehouseIcon)
Vue.component('workspace-setting-icon', WorkspaceSettingIcon)
Vue.component('org-workspace-icon', OrgWorkspaceIcon)
Vue.component('org-personnel-icon', OrgPersonnelIcon)
Vue.component('org-lab-manage-icon', OrgLabManageIcon)
Vue.component('credit-icon', CreditIcon)
Vue.component('individual-icon', IndividualIcon)
Vue.component('signature-icon', SignatureIcon)

const linkMarketplace = () => {
  const token = localStorage.getItem('jwt_token')
  const org_id =
    Vue.prototype.$globalOrg?.id ||
      (typeof localStorage.getItem('global_org') !== 'undefined' &&
        appUtils.isJSON(localStorage.getItem('global_org')))
      ? JSON.parse(localStorage.getItem('global_org'))?.id
      : null
  const ws_id =
    Vue.prototype.$globalClinic?.id ||
    Number(localStorage.getItem('global_wp_id'))
  const domain = window.location.origin

  if (!token || (!ws_id && !org_id)) return ''

  if (process.env.NODE_ENV === 'development' && domain.includes('localhost')) {
    return `http://localhost:8087/#/auto-login?token=${token}&ws_id=${ws_id}&org_id=${org_id}`
  } else if (process.env.NODE_ENV === 'development') {
    return `https://dev.internal.marketplace.hodo.app/#/auto-login?token=${token}&ws_id=${ws_id}&org_id=${org_id}`
  } else {
    return `https://internal.marketplace.hodo.app/#/auto-login?token=${token}&ws_id=${ws_id}&org_id=${org_id}`
  }
}

const linkMarketplaceWarehouse = () => {
  const token = localStorage.getItem('jwt_token')
  const org_id =
    Vue.prototype.$globalOrg?.id ||
      (typeof localStorage.getItem('global_org') !== 'undefined' &&
        appUtils.isJSON(localStorage.getItem('global_org')))
      ? JSON.parse(localStorage.getItem('global_org'))?.id
      : null
  const ws_id =
    Vue.prototype.$globalClinic?.id ||
    Number(localStorage.getItem('global_wp_id'))
  const mode =
    Vue.prototype.$appMode ||
    Number(localStorage.getItem('app_mode')) ||
    APP_MODE.workspace
  const domain = window.location.origin
  const pathWs = `#/auto-login?token=${token}&ws_id=${ws_id}`
  const pathOrg = `#/auto-login?token=${token}&org_id=${org_id}`
  let link = ''

  if (!token || (!ws_id && !org_id)) return ''

  if (process.env.NODE_ENV === 'development' && domain.includes('localhost')) {
    if (mode === APP_MODE.workspace) {
      link = `https://localhost:8087/${pathWs}`
    } else if (mode === APP_MODE.organization) {
      link = `https://localhost:8087/${pathOrg}`
    }
  } else if (process.env.NODE_ENV === 'development') {
    if (mode === APP_MODE.workspace) {
      link = `https://dev.marketplace.hodo.app/${pathWs}`
    } else if (mode === APP_MODE.organization) {
      link = `https://dev.marketplace.hodo.app/${pathOrg}`
    }
  } else {
    if (mode === APP_MODE.workspace) {
      link = `https://marketplace.hodo.app/${pathWs}`
    } else if (mode === APP_MODE.organization) {
      link = `https://marketplace.hodo.app/${pathOrg}`
    }
  }
  return link
}

const getOrgModel = () => {
  return Vue.prototype.$orgModel || localStorage.getItem('org_model') || ''
}

export const menuSidebar = [
  {
    title: 'side_menu.lbl_home',
    icon: 'home-icon',
    path: '/',
    name: 'HomePage',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_organization',
    icon: 'org-manage-icon',
    path: '/organization/management',
    name: 'OrganizationManagement',
    mode: APP_MODE.organization,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'conference.lbl_conference',
    icon: 'conference-icon',
    path: '/conference',
    name: 'WorkspaceConference',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.conference,
    badge: {
      label: 'circle_feature.lbl_new',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'conference.lbl_conference_management',
    icon: 'conference-icon',
    path: '/organization/conference-list',
    name: 'OrgConferenceList',
    mode: APP_MODE.organization,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.conference,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_health_book',
    icon: 'health-record-icon',
    path: '/health-records',
    name: 'HealthRecords',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.skb,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_medical_record',
    icon: 'electronic-medical-record-icon',
    path: '/electronic-medical-records',
    name: 'ListBenhAn',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.badt,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_care_plan_overview',
    icon: 'care-plan-overview-icon',
    path: '/health-record-overview',
    name: 'HealthRecordOverview',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.hsskOverview,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_care_plan',
    icon: 'care-plan-icon',
    path: '/doctor/patient-diseases',
    name: 'PersonDiseasesManagement',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.hssk,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_patient',
    icon: 'patient-icon',
    path: '/doctor/contact-management',
    name: 'ContactList',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_admission',
    icon: 'admission-icon',
    path: '/doctor/patient-reception',
    name: 'AppointmentManagementV2',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.reception,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_examination_schedule',
    icon: 'examination-schedule-icon',
    path: '/doctor/patient-visit',
    name: 'PatientVisitManagement',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.examination,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_room_management',
    icon: 'room-management-icon',
    path: '/room-management',
    name: 'RoomManagement',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.bedmgnt,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_schedule',
    icon: 'schedule-icon',
    path: '/doctor/work-schedules',
    name: 'WorkSchedules',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.schedule,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_consultation',
    icon: 'consultation-icon',
    path: '/hoi-chan',
    name: 'ListMdt',
    mode: null,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_meeting',
    icon: 'meeting-icon',
    path: '/meeting',
    name: 'Meeting',
    mode: null,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_chat',
    icon: 'chat-icon',
    path: '/chat',
    name: 'ChatPage',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_doctor_circle',
    icon: 'circle-icon',
    path: '/circle',
    name: 'CircleList',
    mode: APP_MODE.workspace,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_ecom_marketplace',
    icon: 'ecom-marketplace-icon',
    path: linkMarketplace(),
    mode: APP_MODE.workspace,
    is_redirect: true,
    has_clinic: false,
    feature: FEATURE_SERVICES.ecomMk,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'Quản lý chợ',
    icon: 'ecom-marketplace-warehouse-icon',
    path: linkMarketplaceWarehouse(),
    mode: null,
    is_redirect: true,
    has_clinic: false,
    feature: FEATURE_SERVICES.ecomWarehouse,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_ws_setting',
    icon: 'workspace-setting-icon',
    path: '/ws-mgmt',
    name: 'ClinicPersonnelManage',
    mode: APP_MODE.workspace,
    org_model: getOrgModel(),
    is_redirect: false,
    has_clinic: true,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_workspace',
    icon: 'org-workspace-icon',
    path: '/organization/workspace',
    name: 'OrganizationListWs',
    mode: APP_MODE.organization,
    org_model: getOrgModel(),
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_personnel',
    icon: 'org-personnel-icon',
    path: '/manage',
    name: 'OrgEmployeeManage',
    mode: APP_MODE.organization,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_ecommerce.hospital',
    icon: 'org-lab-manage-icon',
    path: '/organization/ecom',
    name: 'OrganizationEcom',
    mode: APP_MODE.organization,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.ecom,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'HODO Credit',
    icon: 'credit-icon',
    path: '',
    mode: APP_MODE.organization,
    is_redirect: false,
    has_clinic: false,
    feature: FEATURE_SERVICES.credit,
    badge: {
      label: '',
      value: 0
    },
    sub_menu: [
      {
        title: 'Nạp điểm',
        path: '/organization/credit/deposit',
        name: 'HodoCreditDeposit'
      }
    ],
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_individual',
    icon: 'individual-icon',
    path: '/doctor/setting',
    name: 'DoctorSetting',
    mode: null,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  },
  {
    title: 'side_menu.lbl_digital_signature',
    icon: 'individual-icon',
    path: '/digital-signature',
    name: 'DigitalSignature',
    mode: null,
    is_redirect: false,
    has_clinic: false,
    feature: null,
    badge: {
      label: '',
      value: 0
    },
    action: (callback) => callback
  }
]
